<template>
  <div class="flex justify-center min-h-screen bg-base-200">
    <!-- THEME SELECTOR -->
    <div class="absolute top-0 right-0">
      <select-theme/>
    </div>

    <!-- PAGE CONTENT -->
    <div class="flex flex-col pt-6 md:w-1/2">
      <p class="text-center p-6 text-2xl">Wave</p>
      <div class="flex flex-row w-full">
        <div class="grid flex-grow card h-72 w-72 rounded-box place-items-center">
          <video id="animation" class="md:h-80"
                 autoplay
                 loop
                 muted
                 playsinline
          >
            <source src="../../assets/Animations/wave.mp4" type="video/mp4">
          </video>
        </div>
      </div>


      <div v-if="!begin && !finished">
        <div>
          <p class="text-center p-6 text-lg">Sometimes we can't ignore our emotions but as we sit with them we feel more
            settled.
            Watch how the wave rises and slowly crashes on the shore. It works that way with emotions too, it starts big
            and scary but slowly crashes down and fades away.</p>
        </div>
        <div class="flex justify-evenly p-2">
          <label @click="beginVideo()" class="btn btn-primary">Let's Begin</label>
        </div>
      </div>

      <div v-if="begin" class="flex justify-evenly p-6">
        <p class="text-7xl">{{countDown}}</p>
      </div>

      <div v-if="finished">
        <div>
          <p class="text-center p-6 text-xl">Great work!</p>
        </div>
        <div class="flex justify-evenly p-2">
          <label @click="routeForward()" class="btn btn-primary">Finish</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import selectTheme from "../../components/selectTheme";
import {routeHelper} from "../../main";

export default {
  name: "Wave",
  components: {
    selectTheme,
  },
  data() {
    return {
      begin: false,
      finished: false,
      countDown: 25
    }
  },
  mounted() {
    routeHelper.checkRoute(this.$route.params.previous, "strategypicker", this.$router)
  },
  methods: {
    routeForward() {
      this.$router.push({name: "checkemotion", params: {"previous": "strategy"}})
    },
    beginVideo() {
      this.begin = true;
      this.countDownTimer()
    },

    countDownTimer() {
      if(this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      } else {
        this.begin = null;
        this.finished = true
      }
    },
  },
}
</script>

<style scoped>

</style>
