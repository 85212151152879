<template>
  <div class="flex justify-center min-h-screen bg-base-200">
    <!-- THEME SELECTOR -->
    <div class="absolute top-0 right-0">
      <select-theme/>
    </div>

    <!-- PAGE CONTENT -->
    <div class="flex flex-col pt-6 md:w-1/2">
      <p class="text-center p-6 text-4xl" >Heel Toe Walk</p>
      <div class="flex flex-row w-full">
        <div class="grid flex-grow card place-items-center">
          <video id="animation" class="md:h-96"
                 autoplay
                 loop
                 muted
                 playsinline
          >
            <source :src="require(`../../assets/Animations/HeelToeHVEC${randomVid}.mov`)" type='video/mp4; codecs="hvc1"'>
            <source :src="require(`../../assets/Animations/HeelToe${randomVid}.webm`)" type="video/webm">
          </video>
        </div>
      </div>

      <div v-if="!begin && !finished">
        <div>
          <p class="text-center p-6 text-lg">Be sure the path is clear and count to 30 steps walking heel toe. Look down and focus on your heel touching your toe.</p>
        </div>
        <div class="flex justify-evenly p-2">
          <label @click="routeBegin()" class="btn btn-primary">Let's Begin</label>
        </div>
      </div>

      <div v-if="begin" class="flex justify-evenly p-6">
        <p class="text-7xl">{{countDown}}</p>
      </div>

      <div v-if="finished">
        <div>
          <p class="text-center p-6 text-xl">Great work!</p>
        </div>
        <div class="flex justify-evenly p-2">
          <label @click="routeForward()" class="btn btn-primary">Finish</label>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import selectTheme from "../../components/selectTheme";
import {routeHelper} from "../../main";

export default {
  name: "HeelToe",
  data() {
    return {
      finished: null,
      begin: false,
      countDown: 35,
      randomVid: 0,
    }
  },
  mounted() {
    routeHelper.checkRoute(this.$route.params.previous, "strategypicker", this.$router)

    this.randomVid = Math.floor(Math.random() * 2);
  },
  components: {
    selectTheme,
  },
  methods: {
    routeForward() {
      this.$router.push({name: "checkemotion", params: {"previous": "strategy"}})
    },
    routeBegin() {
      this.begin = true;
      this.countDownTimer()
    },

    countDownTimer() {
      if(this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer()
        }, 1000)
      } else {
        this.begin = null;
        this.finished = true
      }
    },
  },
}
</script>

<style scoped>

</style>
