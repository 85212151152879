<template>
  <div>
    <div class="flex flex-col pt-6">
      <p class="text-center p-6 text-2xl" >Welcome to C.O.P.E.</p>
      <p class="text-center p-6 text-lg" >Thank you for taking part in our study about emotions. Please begin by selecting your mood today.</p>
    </div>
    <div class="flex justify-evenly p-2">
      <label @click="navigateEmotionSelect" class="btn btn-primary">Begin</label>
    </div>
  </div>
</template>

<script>
import {routeHelper} from "../main";

export default {
  name: "Welcome",
  mounted() {
    routeHelper.checkRoute(this.$route.params.previous, "base", this.$router)
  },
  methods: {
    navigateEmotionSelect() {
      this.$router.push({name: 'emotionpicker', params: {"previous": "welcome"}} )
    },
  },
}
</script>

<style scoped>

</style>
