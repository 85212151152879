<template>
  <div class="flex justify-center min-h-screen bg-base-200">
    <!-- THEME SELECTOR -->
    <div class="absolute top-0 right-0">
      <select-theme/>
    </div>

    <!-- PAGE CONTENT -->
    <div class="flex flex-col pt-6 md:w-1/2">
      <p class="text-center p-6 text-2xl" >Break Wall</p>
      <div class="flex flex-row w-full">
        <div class="grid flex-grow card h-72 w-72 rounded-box place-items-center">
                    <video id="animation" class="md:h-96"
                           muted
                           playsinline
                    >
                      <source :src="require('../../assets/Animations/Wrecking_' + selectedWall + 'HVEC.mov')" type='video/mp4; codecs="hvc1"'>
                      <source id="source" :src="require('../../assets/Animations/walls/Wrecking_' + selectedWall + '.webm')" type="video/webm">
                    </video>
        </div>
      </div>
      <div>
        <p v-if="!begin && !finished" class="text-center p-6 text-lg">When you're feeling a bit low it can be hard to break through that feeling.
        This activity is all about breaking the wall. Pick a low mood to label your wall with, now break it down! Tap the wrecking ball to break down the wall.<p/>
        <p v-if="begin" class="text-7xl text-center p-2"> {{ this.countDown }}</p>
      </div>
      <div class="p-2" v-if="!begin && !finished">
        <div class="flex  justify-around pr-5">
          <div class="btn btn-primary" v-on:click="hover('Angry')">Angry</div>
          <div class="btn btn-primary" v-on:click="hover('Frustrated')">Frustrated</div>
          <div class="btn btn-primary" v-on:click="hover('Sad')">Sad</div>
        </div>
      </div>
      <div class="flex justify-center pt-10">
        <label @click="beginVideo" v-if="!begin  && !finished" class="btn btn-primary">Let's Begin</label>
        <label v-if="finished" @click="routeForward" class="btn btn-primary">Finish</label>
      </div>
    </div>
  </div>
</template>

<script>
import selectTheme from "../../components/selectTheme";
import {routeHelper} from "../../main";

export default {
  name: "BreakWall",
  data() {
    return {
      selectedWall: "Angry",
      begin: false,
      countDown: 10,
      finished: false
    }
  },
  components: {
    selectTheme,
  },
  mounted() {
    routeHelper.checkRoute(this.$route.params.previous, "strategypicker", this.$router)
  },
  methods: {
    routeForward() {
      this.$router.push({name: "checkemotion", params: {"previous": "strategy"}})
    },

    hover(selected) {
      let animationPlayer = document.getElementById('animation');
      this.selectedWall = selected;
      animationPlayer.load();
    },

    beginVideo() {
      let animationPlayer = document.getElementById('animation');
      animationPlayer.load();
      animationPlayer.play();
      this.countDownTimer();
      this.begin = true;
    },
    countDownTimer() {
      if(this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      } else {
        this.begin = false
        this.finished = true;
      }
    },
  },
}
</script>

<style scoped>

</style>
