<template>
  <div class="hero min-h-screen bg-base-200">


    <div class="flex-col justify-center hero-content lg:flex-row">

      <div class="text-center lg:text-left">
        <h1 class="mb-5 text-5xl font-bold">
          C.O.P.E.
        </h1>
      </div>


      <div class="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">

        <div class="card-body">

          <div class="form-control">
            <label class="label">
              <span class="label-text">Usercode</span>
            </label>
            <input v-model="usercode" type="text" placeholder="usercode" class="input input-bordered">
          </div>
          <div class="text-red-600">
            {{loginStatus}}
          </div>
          <div v-if="!loggingIn" class="form-control mt-6">
            <input @click="loginButton" type="button" value="Login" class="btn btn-primary">
          </div>
          <div v-else class="form-control mt-6">
            <input type="button" value="Loading..." class="btn btn-disabled">
          </div>


        </div>

      </div>
      <div class="absolute top-0 right-0">
        <select-theme/>
      </div>

    </div>
  </div>
</template>

<script>
import SelectTheme from "@/components/selectTheme";
import {firebaseHelper} from "../main";

export default {
name: "Login",
  components: {
    SelectTheme
  },
  mounted() {
    this.api = firebaseHelper;
  },
  data() {
    return {
      usercode: "",
      password: "Pizza12",
      loginStatus : "",
      loggingIn: false
    }
  },
  methods: {
    async loginButton() {
      this.loggingIn = true;
      this.loginStatus = await this.api.firebaseLogin(this.usercode, this.password)
      if (this.loginStatus === undefined) {
        // await this.api.createUserLog(); TODO Replace 
        this.routeForward();
      }
      this.loggingIn = false;
    },

    routeForward() {
      firebaseHelper.db.collection("users").doc(localStorage.getItem("uid")).get().then((doc) => {
        if (doc.exists) {
            this.$router.push({name: 'welcome', params: {"previous": "base"}});
        }
      });
    },

    switchTheme(newTheme) {
      document.documentElement.setAttribute("data-theme", newTheme);
    }
  },

}
</script>

<style scoped>

</style>
