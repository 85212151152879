<template>
  <div class="flex justify-center min-h-screen bg-base-200">
    <!-- THEME SELECTOR -->
    <div class="absolute top-0 right-0">
      <select-theme/>
    </div>

    <!-- PAGE CONTENT -->
    <div class="flex flex-col pt-6 md:w-1/2">
      <p class="text-center p-6 text-4xl" >Muscle Tighten</p>
      <div class="flex flex-row w-full">
        <div class="grid flex-grow card place-items-center">
          <video id="animation" class="md:h-96"
                 muted
                 playsinline
                 loop
                 autoplay
          >
            <source :src="require('../../assets/Animations/tensing' + randomVid + '.webm')" type="video/webm">
            <source :src="require('../../assets/Animations/TensingHVEC' + randomVid + '.mov')" type='video/mp4; codecs="hvc1"'>
          </video>
        </div>
      </div>
      <div>
        <p v-if="!begin && !finished" class="text-center p-2">Watch the animation. Scrunch your toes up tight and then release.
          Follow along with the animation and work your way up your body. Focus on tightening your muscles and releasing, try not to think about anything else.</p>
        <p v-if="begin" class="text-7xl text-center"> {{ this.countDown }}</p>
      </div>
      <div class="flex justify-evenly p-2">
        <label v-if="!begin && !finished" @click="beginVideo" class="btn btn-primary">Let's Begin</label>
        <label v-if="finished" @click="routeForward" class="btn btn-primary">Finish</label>
      </div>
      </div>
  </div>
</template>

<script>
import selectTheme from "../../components/selectTheme";
import {routeHelper} from "../../main";

export default {
  name: "MuscleTighten",
  components: {
    selectTheme,
  },
  data() {
    return {
      begin: false,
      finished: false,
      countDown: 35,
      randomVid: 0
    }
  },
  mounted() {
    routeHelper.checkRoute(this.$route.params.previous, "strategypicker", this.$router)
    this.randomVid = Math.floor(Math.random() * 2);
  },

  methods: {
    routeForward() {
      this.$router.push({name: "checkemotion", params: {"previous": "strategy"}})
    },
    beginVideo() {
      let animationPlayer = document.getElementById('animation');
      animationPlayer.currentTime = 0;
      this.countDownTimer();
      this.begin = true;
    },
    countDownTimer() {
      if(this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      } else {
        this.begin = false
        this.finished = true;
      }
    },
  },
}
</script>

<style scoped>

</style>
