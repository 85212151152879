import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBuq_rlKTZPVdtqmIHuY63u1ZwUUhip5pY",
    authDomain: "cope-69142.firebaseapp.com",
    projectId: "cope-69142",
    storageBucket: "cope-69142.appspot.com",
    messagingSenderId: "11446674879",
    appId: "1:11446674879:web:2c128d5bca32e51513ad0a",
    measurementId: "G-VYG2DNCGC6"
};

export class FirebaseHelper {
    constructor() {
        firebase.initializeApp(firebaseConfig);
        this.db = firebase.firestore();
    }
    async firebaseLogin(usercode, password) {
        let success = firebase.auth().signInWithEmailAndPassword(usercode+"@cope.com", password)
            .then((userCredential) => {
                // Signed in
                let user = userCredential.user;
                this.usercode = usercode;
                localStorage.setItem("uid", user.uid);
            })
            .catch((error) => {
                let errorCode = error.code;
                let errorMessage = error.message;
                console.log(errorCode, errorMessage);
                success = errorMessage;
                return success;
                // localStorage.setItem("loginStatus", errorMessage);
            });
        return success
    }

    /**
     * Creates a users entry in the DB if it doesnt exist, saves ref for use when saving later.
     */
    async createUserLog() {
        this.startTime = new Date();
        let timestamp = new firebase.firestore.Timestamp.fromDate(this.startTime);

        //get the users id
        this.db.collection("users").doc(localStorage.getItem("uid")).get().then((doc) => {
            var userId = doc.data().userId;
            let data = {
                userId : userId,
                start: timestamp,
                end: timestamp,
                initialEmotionSelected: "",
                initialRating: -1,
                initialDescription: "",
                postRating: -1,
                activitySelected: "",
                givenStrategy: "",
                durationSeconds: 0,
            };
            this.logRef =  this.db.collection("log").doc();
            this.userRef = this.db.collection("users").doc(localStorage.getItem("uid")).collection("log").doc();
             this.logRef.set(data);
             this.userRef.set(data);
        });
    }

    /**
     * Updates all of the datapoints when a user is finished with their login.
     */
    async saveData() {
        let finishTime = new Date();
        let data = {
            initialEmotionSelected: localStorage.getItem("chosenEmotion"),
            initialRating: parseInt(localStorage.getItem("initialMood")),
            initialDescription: localStorage.getItem("initialDescription"),
            postRating: parseInt(localStorage.getItem("postMood")),
            end: new firebase.firestore.Timestamp.fromDate(finishTime),
            durationSeconds: (finishTime - this.startTime) / 1000,
            activitySelected: localStorage.getItem("selectedMethod"),
            givenStrategy: localStorage.getItem("givenRoute"),
        };
        await this.logRef.update(data);
        await this.userRef.update(data);
    }

    /**
     * Sets a users firstTime flag to false
     */
    async setFirstTime(uid) {
        this.db.collection("users").doc(uid).update({
            firstTime: false
        })
        ;
    }
}
