<template>
  <div class="flex justify-center min-h-screen bg-base-200">
      <!-- THEME SELECTOR -->
      <div class="absolute top-0 right-0">
        <select-theme/>
      </div>

      <!-- MODAL  -->
      <div class="fixed z-50 justify-center inset-0 pt-10"
            v-if="this.showModal" @focus="toggleModal()">
        <div class="bg-base-100 max-w-2xl rounded-2xl shadow-2xl m-auto">
          <div class="">
            <p class="text-center text-xl p-4">So you're feeling {{ emotion }}</p>
            
            <!-- TODO: Change content to emoji -->
            <div class="h-32 mx-auto w-40 card bg-base-300 rounded-box place-items-center">
              <img class="mx-auto h-full" :src="require('@/assets/emojis/' + imageEmotion + '.png')">
            </div>
            <div class="form-control p-6">
              <!-- MOOD RATER -->
              <label class="label p-2">
                <span class="label-text">How {{ emotion }} are you feeling?</span>
              </label>
              <div class="p-2 flex justify-between">
                <p class="text-xs">Not at all</p>
                <p class="text-xs">Very</p>
              </div>
              <div class="flex justify-between">
                <p>0</p>
                <p>1</p>
                <p>2</p>
                <p>3</p>
                <p>4</p>
                <p>5</p>
                <p>6</p>
                <p>7</p>
                <p>8</p>
                <p>9</p>
                <p>10</p>
              </div>
              <input v-model="currentMood" class=""
                     type="range" min="0" max="10"  step ="1"
              />

                <label class="label">
                    <span class="label-text">Would you like to tell us why you feel this way?</span>
                </label> 
                <textarea v-model="description" class="textarea h-24 textarea-bordered textarea-accent"></textarea>
                


            </div>
            <div class="flex justify-evenly p-2">
              <label v-on:click="navigateStrategySelect()" class="btn btn-primary">Accept</label> 
              <label v-on:click="toggleModal()" class="btn">Close</label>
            </div>
          </div>
        </div>
      </div>

    <!-- PAGE CONTENT -->
    <!-- TODO: Change content to emoji -->
    <div class="flex flex-col pt-6 px-4">
        <p class="text-center p-6 text-2xl" >How are you feeling right now?</p>
        <div class="flex flex-row w-full">
            <div v-on:click="toggleModal('Happy', 'happy')" class="grid flex-grow h-32 w-32 card bg-base-300 rounded-box place-items-center">
              <img class="mx-auto h-32" src="../assets/emojis/happy.png">
            </div>
            <div class="divider divider-vertical"></div> 
            <div v-on:click="toggleModal('Sad', 'sad')" class="grid flex-grow h-32 w-32 card bg-base-300 rounded-box place-items-center">
              <img class="mx-auto h-32" src="../assets/emojis/sad.png">
            </div>
        </div>          
        <div class="divider"></div> 
        <div class="flex justify-center flex-row w-full">
            <div v-on:click="toggleModal('Tired', 'tired')" class="grid flex-grow h-32 w-32 card bg-base-300 rounded-box place-items-center">
              <img class="mx-auto h-32" src="../assets/emojis/tired.png">
            </div>
            <div class="divider divider-vertical"></div>
            <div v-on:click="toggleModal('Hungry', 'hungry')" class="grid flex-grow h-32 card w-32 bg-base-300 rounded-box place-items-center">
              <img class="mx-auto h-32" src="../assets/emojis/hungry.png">
            </div>
        </div>     
        <div class="divider"></div> 
        <div class="flex flex-row w-full">
            <div v-on:click="toggleModal('Angry', 'angry')" class="grid flex-grow h-32 card w-32 bg-base-300 rounded-box place-items-center">
              <img class="mx-auto h-32" src="../assets/emojis/angry.png">
            </div>
            <div class="divider divider-vertical"></div>
            <div v-on:click="toggleModal('Frustrated', 'frustrated')" class="grid flex-grow h-32 card w-32 bg-base-300 rounded-box place-items-center">
              <img class="mx-auto h-32" src="../assets/emojis/frustrated.png">
            </div>
        </div> 
        <div class="divider"></div> 
        <div class="flex flex-row w-full">
            <div v-on:click="toggleModal('Grateful', 'grateful')" class="grid flex-grow h-32 w-32 card bg-base-300 rounded-box place-items-center">
              <img class="mx-auto h-32" src="@/assets/emojis/grateful.png">
            </div>
            <div class="divider divider-vertical"></div>
            <div v-on:click="toggleModal('Loved', 'loved')" class="grid flex-grow h-32 w-32 card bg-base-300 rounded-box place-items-center">
              <img class="mx-auto h-32" src="../assets/emojis/loved.png">
            </div>
        </div>  
    </div>
    

  </div>
</template>

<script>
import SelectTheme from "@/components/selectTheme";
import {firebaseHelper, routeHelper} from "../main";

export default {
name: "EmotionPicker",
  components: {
    SelectTheme,
  },
  mounted() {
    routeHelper.checkRoute(this.$route.params.previous, "welcome", this.$router)
    this.api = firebaseHelper;
  },
  data() {
    return {
      showModal: false,
      imageEmotion: null,
      currentMood: 5,
      emotion: "",
      description: "",
    }
  },
  methods: {
    switchTheme(newTheme) {
      document.documentElement.setAttribute("data-theme", newTheme);
    },

    toggleModal: function (emotion, imageEmotion) {
      this.emotion = emotion
      this.imageEmotion = imageEmotion
      this.showModal = !this.showModal;
    },

    navigateStrategySelect() {
      localStorage.setItem('chosenEmotion', this.emotion)
      localStorage.setItem("initialMood", this.currentMood);
      this.description = this.description.replace(/,/g, " ")
      localStorage.setItem("initialDescription", this.description);
      this.$router.push({name: 'strategypicker', params: {"previous": "emotionpicker"}} )

    },

  },

}
</script>

<style scoped>

</style>