<template>
  <div>
    <div class="flex flex-col pt-6">
      <p class="text-center p-6 text-2xl" >Kīwaha</p>
      <p class="text-center p-6 text-lg" >{{ phrase }}</p>
    </div>
    <div class="flex justify-evenly p-2">
      <label @click="logout" class="btn btn-primary">Finish</label>
    </div>
  </div>
</template>

<script>
import {firebaseHelper, routeHelper} from "../main";

export default {
  name: "Finish",
  mounted() {
    routeHelper.checkRoute(this.$route.params.previous, "checkemotion", this.$router)
    this.api = firebaseHelper;
    let randomPhrases = ['You’re awesome – Kei runga noa atu koe', 'Have a good day – Kia pai tō rā', 'Great work – Ka tino pai tō mahi', 'Be kind to yourself – Me atawhai i a koe anō', 'It’s okay to ask for help when you need it – E pai ana kia pātai mō ētahi āwhina i ngā wā e tika ana', ' This is a beautiful day – He rā ātaahua tēnei'];
    this.phrase = randomPhrases[Math.floor(Math.random() * randomPhrases.length)];
  },
  data() {
    return {
      phrase: ""
    }
  },
  methods: {
    logout() {
      // this.api.saveData(); TODO Replace 
      this.$router.push('/')
    }
  },
}
</script>

<style scoped>

</style>