<template>
  <div class="flex justify-center min-h-screen bg-base-200">
    <!-- THEME SELECTOR -->
    <div class="absolute top-0 right-0">
      <select-theme/>
    </div>

    <!-- PAGE CONTENT BEFORE START -->
    <div v-if="!beginClicked" class="flex flex-col pt-6 md:w-1/2">
      <p class="text-center p-6 text-4xl" >Grateful List</p>
      <div class="flex flex-row w-full">
        <div class="grid flex-grow card place-items-center">
          <video id="animation" class="md:h-96"
                 autoplay
                 loop
                 muted
                 playsinline
          >
            <source src="../../assets/Animations/GratefulList.mp4" type="video/mp4">
          </video>
        </div>
      </div>
      <div>
        <p class="text-center p-6 text-lg">A good way to feel warm and fuzzy is to think about the things and people we are glad we have.
          Please type in the textbox 5 things/people you are glad to have in your life.</p>
      </div>
      <div class="flex justify-evenly p-2">
        <label @click="beginClicked = true" class="btn btn-primary">Let's Begin</label>
      </div>
    </div>

    <!-- PAGE CONTENT AFTER START -->
    <div v-if="beginClicked" class="flex flex-col pt-6 md:w-1/2">
      <p class="text-center p-6 text-4xl" >Grateful List</p>

      <!-- INSTRUCTION -->
      <div>
        <p class="text-center p-6 text-lg">Please type in the textbox 5 things/people you are glad to have in your life.</p>
      </div>

      <!-- FINISH BUTTON -->
      <div v-if="hitMaxItems" class="flex justify-evenly p-6">
        <label @click="routeForward" class="btn btn-accent">Finish</label>
      </div>

      <!-- ADD BUTTON -->
      <div v-if="!hitMaxItems" class="flex justify-evenly p-6">
        <input v-model="currentItem" maxlength="32" class="input input-accent input-bordered w-4/5"/>
        <label @click="addItemToList(currentItem)" class="btn p-2 btn-primary">Add</label>
      </div>

      <!-- LIST OF ITEMS -->
      <div v-for="item in gratefulList" :key="item.message" class="p-2">
        <div class="card bg-primary shadow-xl h-16">
          <div class="flex justify-between h-full m-4">
            <h6 class="text-primary-content text-center font-bold">{{ item.message }}</h6>
            <img @click="removeItem(item)" class="cursor-pointer h-1/2" src="../../assets/redX.png">
          </div>
        </div>
      </div>

      <!-- ERROR -->
      <div v-if="itemError" class="p-4">
         <div class="alert alert-error">
           <div class="flex-1">
             <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="w-6 h-6 mx-2 stroke-current">
               <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"></path>
             </svg>
             <label>{{ errorMessage }}</label>
           </div>
         </div>
      </div>



    </div>

  </div>
</template>

<script>
import selectTheme from "../../components/selectTheme";
import {routeHelper} from "../../main";

export default {
  name: "GratefulList",
  data() {
    return {
      beginClicked: false,
      gratefulList: [],
      currentItem: "",
      errorMessage: "",
      hitMaxItems: false,
      itemError: false,

    }
  },
  components: {
    selectTheme,
  },
  mounted() {
    routeHelper.checkRoute(this.$route.params.previous, "strategypicker", this.$router)
  },
  methods: {
    routeForward() {
      this.$router.push({name: "checkemotion", params: {"previous": "strategy"}})
    },

    addItemToList(item) {
      if (item.length <= 0) {
        this.itemError = true;
        this.errorMessage = "You must enter an answer into the textbox."
      } else if (this.isInGratefulList(item)) {
        this.itemError = true;
        this.errorMessage = "That's already in your list."
      } else if (item.length > 32) {
        this.itemError = true;
        this.errorMessage = "Your option can only be 32 characters long."
      } else {
        if (this.gratefulList.length < 5) {
          this.gratefulList.unshift({message: item});
          this.currentItem = "";
          this.itemError = false;
          if (this.gratefulList.length === 5) {
            this.hitMaxItems = true;
          }
        }
      }
    },

    removeItem(item) {
      let index = this.gratefulList.indexOf(item);
      if (index > -1) {
        this.gratefulList.splice(index, 1);
        if (this.gratefulList.length < 5) {
          this.hitMaxItems = false;
        }
      }
    },

    isInGratefulList(value) {
      let inList = false;
      for (let i = 0; i < this.gratefulList.length; i++) {
        if (this.gratefulList[i].message === value) {
          inList = true;
        }
      }
      return inList
    }
  },
}
</script>

<style scoped>

</style>
