<template>
  <div class="flex justify-center min-h-screen bg-base-200">
    <!-- THEME SELECTOR -->
    <div class="absolute top-0 right-0">
      <select-theme/>
    </div>

    <!-- PAGE CONTENT -->
    <div class="flex flex-col pt-6">
      <div v-if="isSelecting">
        <p class="text-center p-6 text-4xl">Choose an activity type</p>
        <div class="flex flex-row">
          <div v-on:click="randomCBTRoute()" class="flex-grow h-36 card place-items-center">
            <img class="h-full" src="@/assets/brain.png">
          </div>
        </div>
        <div class="divider"></div>
        <div class="flex flex-row w-full">
          <div v-on:click="randomBehaviouralRoute()" class="flex-grow h-36 card place-items-center">
            <img class="h-full" src="@/assets/running.png">
          </div>
        </div>
        <div class="divider"></div>
        <div class="flex flex-row w-full">
          <div v-on:click="randomDBTRoute()" class="flex-grow h-36 card place-items-center">
            <img class="h-full" src="@/assets/yoga.png">
          </div>
        </div>
      </div>

      <!--selection made-->
      <div v-else class="justify-center my-auto">
        <div>
          Now it is time to do an activity
        </div>
        <div class="flex justify-center pt-10">
          <label @click="routeForward" class="btn btn-primary">Let's Go!</label>
        </div>
      </div>

    </div>


  </div>
</template>

<script>
import SelectTheme from "@/components/selectTheme";
import {firebaseHelper, routeHelper} from "../main";
// import DBTModal from "../components/modals/DBTModal";
// import BehaviouralModal from "../components/modals/BehaviouralModal";
// import CBTModal from "../components/modals/CBTModal";

export default {
  name: "StrategyPicker",
  created() {
    this.$root.$refs.StrategyPicker = this;
  },
  components: {
    SelectTheme,
  },
  mounted() {
    routeHelper.checkRoute(this.$route.params.previous, "emotionpicker", this.$router)
    this.api = firebaseHelper;
  },
  data() {
    return {
      showDBT: false,
      showCBT: false,
      showBehavioural: false,
      isSelecting: true,
      route: "",
    }
  },
  methods: {
    switchTheme(newTheme) {
      document.documentElement.setAttribute("data-theme", newTheme);
    },
    randomBehaviouralRoute() {
      this.isSelecting = false;
      let routes = ['tapping', 'muscletighten', 'heeltoe'];
      this.route = routes[Math.floor(Math.random() * routes.length)];
      this.setSelectedMethod("Behavioural", this.route );
    },
    randomCBTRoute() {
      this.isSelecting = false;
      let routes = ['breakwall', 'gratefullist', 'identifyemotions'];
      this.route = routes[Math.floor(Math.random() * routes.length)];
      this.setSelectedMethod("CBT", this.route );
    },
    randomDBTRoute() {
      this.isSelecting = false;
      let routes = ['bodyscan', 'breathing', 'glitterjar', 'safari', 'wave'];
      this.route = routes[Math.floor(Math.random() * routes.length)];
      this.setSelectedMethod("DBT", this.route );
    },

    routeForward() {
      this.$router.push({name: this.route, params: {"previous": "strategypicker"}})
    },

    /**
     * Sets local storage to the selected strategy and route.
     * @param method
     * @param route
     */
    setSelectedMethod(method, route) {
      localStorage.setItem("selectedMethod", method);
      localStorage.setItem("givenRoute", route);
    }
    // toggleDBTModal: function () {
    //   this.showDBT = !this.showDBT;
    // },
    // toggleCBTModal: function () {
    //   this.showCBT = !this.showCBT;
    // },
    // toggleBehaviouralModal: function () {
    //   this.showBehavioural = !this.showBehavioural;
    // },

  },

}
</script>

<style scoped>

</style>