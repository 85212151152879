<template>
  <div class="flex justify-center min-h-screen bg-base-200">
    <!-- THEME SELECTOR -->
    <div class="absolute top-0 right-0">
      <select-theme/>
    </div>

    <!-- PAGE CONTENT -->
    <div class="flex flex-col pt-6 md:w-1/2">
      <p class="text-center p-6 text-4xl" >Body Scan</p>
      <div class="flex flex-row w-full">
        <div class="grid flex-grow card place-items-center">
          <div v-if="begin">
            <img :src="getSense()" class="h-96 p-4" alt=""/>
          </div>
          <div v-if="!begin && !finished">
            <img :src="getSense()" class="h-96 p-4" alt=""/>
          </div>
        </div>
      </div>
      <div>
        <div v-if="!begin && !finished">
          <p class="text-center p-6 text-lg">Think about your 5 senses. What can you see right now? How does it smell?
            What can you touch around you? What can you hear? Can you taste anything? Focus on these five things for 25 seconds, a timer will count you down.</p>
        </div>
        <div v-if="begin">
          <p class="text-center p-6 text-lg">{{ this.currentSenseMsg }}</p>
          <div class="flex justify-evenly p-6">
            <input id="senseInput" maxlength="32" class="input input-accent input-bordered w-4/5"/>
            <label @click="clickNext" class="btn p-2 btn-primary">Next</label>
          </div>
        </div>
        <div v-if="finished" class="grid flex-grow card place-items-center">
          <p class="text-4xl pt-16">Great Work!</p>
        </div>
      </div>
      <div class="flex justify-evenly p-6">
        <label v-if="!begin && !finished" @click="routeBegin" class="btn btn-primary">Let's Begin</label>
        <label v-if="finished" @click="routeForward" class="btn btn-primary">Finish</label>
      </div>
    </div>
  </div>
</template>

<script>
import selectTheme from "../../components/selectTheme";
import {routeHelper} from "../../main";

export default {
  name: "BodyScan",
  data() {
    return {
      begin: false,
      finished: false,
      currentSense: null,
      currentSenseMsg: "",
      currentSenseURL: "",
      senses: [""],
      answers: [],
      currentAnswer: "",
      senseURL: "",
      randomImg: 0

    }
  },
  mounted() {
    routeHelper.checkRoute(this.$route.params.previous, "strategypicker", this.$router)

    this.randomImg = Math.floor(Math.random() * 2);

    this.senses = [
      { imgURL: 'hearing', message: 'What can you hear?' },
      { imgURL: 'sight', message: 'What can you see?' },
      { imgURL: 'smell', message: 'What can you smell?' },
      { imgURL: 'taste', message: 'What can you taste?' },
      { imgURL: 'touch', message: 'What can you feel?' },
    ]
  },
  components: {
    selectTheme,
  },
  methods: {
    routeForward() {
      this.$router.push({name: "checkemotion", params: {"previous": "strategy"}})
    },
    routeBegin() {
      this.begin = true;
    },
    randomSense() {
      if (this.senses.length <= 0) {
        this.finished = true;
        this.begin = false;
      } else {
        let sense = this.senses[Math.floor(Math.random()*this.senses.length)];
        this.currentSense = sense;
        this.currentSenseMsg = sense.message;
        this.currentSenseURL = sense.imgURL;
      }
    },
    getSense() {
      this.randomSense();
      return  require(`../../assets/senses/${this.currentSenseURL}${this.randomImg}.png`)
    },
    addItemToList(item, sense) {
      if (item.length <= 0) {
        this.itemError = true;
        this.errorMessage = "You must enter an answer into the textbox."
      } else {
        this.answers.push({targetSense: sense.imgURL, answer: item});
        let index = this.senses.indexOf(sense);
        this.senses.splice(index, 1);
        this.getSense()
      }
    },
    clickNext() {
      let item = document.getElementById('senseInput').value;
      this.addItemToList(item, this.currentSense);
      document.getElementById('senseInput').value = "";

    }
  },
}
</script>

<style scoped>

</style>
