<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>


export default {
  name: 'App',
  
}
</script>

<style src="./assets/styles/index.css">

</style>
