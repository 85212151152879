<template>
  <div class="bg-base-100 max-w-2xl m-auto">
    <div class="">
      <p class="text-center text-4xl p-4">You said you were feeling {{ emotion }}</p>

      <div class="h-32 mx-auto w-40 card bg-base-200 rounded-box place-items-center">
        <img class="mx-auto h-full" :src="require('@/assets/emojis/' + imageEmotion + '.png')">
      </div>
      <div class="form-control p-6">
        <!-- MOOD RATER -->
        <label class="label">
          <span class="label-text">How {{ emotion }} are you feeling now?</span>
        </label>
        <div class="p-2 flex justify-between">
          <p class="text-xs">Not at all</p>
          <p class="text-xs">Very</p>
        </div>
        <div class="flex justify-between">
          <p>0</p>
          <p>1</p>
          <p>2</p>
          <p>3</p>
          <p>4</p>
          <p>5</p>
          <p>6</p>
          <p>7</p>
          <p>8</p>
          <p>9</p>
          <p>10</p>
        </div>
        <input v-model="currentMood" class=""
               type="range" min="0" max="10"  step ="1"
        />
      </div>
      <div class="flex justify-evenly p-2">
        <label @click="navigateForward" class="btn btn-primary">Continue</label>
      </div>
    </div>
  </div>
</template>

<script>
import {routeHelper} from "../main";

export default {
  name: "checkEmotion",
  data() {
    return {
      emotion: null,
      imageEmotion: null,
      currentMood: 5
    }
  },
  mounted() {
    routeHelper.checkRoute(this.$route.params.previous, "strategy", this.$router)
    let localEmotion = localStorage.getItem('chosenEmotion');
    this.emotion = localEmotion;
    this.imageEmotion = localEmotion.toLowerCase()
  },
  methods: {
    navigateForward() {
      localStorage.setItem("postMood", this.currentMood);
      this.$router.push({name: "finish", params: {"previous": "checkemotion"}} )
    }
  },
}
</script>

<style scoped>

</style>