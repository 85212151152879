<template>

<div>
  <div class="dropdown dropdown-left">
    <div tabindex="0" class="m-1 w-12 btn btn-primary">✎</div>
    <ul class="menu dropdown-content bg-none w-52">
      <li v-for="theme in themes"
          :key="theme.id"
          v-on:click="switchTheme($event.target.value)">
          <input type="button" :value="theme.value" class="btn btn-primary">
      </li>
    </ul>
  </div>

</div>
</template>
<script>

export default {
name: "selectTheme",
  data() {
    return {
      themes: [
        { theme: "Light", value: "light" },
        // { theme: "Dark", value: "dark" },
        // { theme: "Cupcake", value: "cupcake" },
        { theme: "Bumblebee", value: "bumblebee" },
        { theme: "Emerald", value: "emerald" },
        { theme: "Corporate", value: "corporate" },
        // { theme: "Synthwave", value: "synthwave" },
        // { theme: "Retro", value: "retro" },
        // { theme: "Cyberpunk", value: "cyberpunk" },
        // { theme: "Valentine", value: "valentine" },
        // { theme: "Halloween", value: "halloween" },
        // { theme: "Garden", value: "garden" },
        // { theme: "Forest", value: "forest" },
        // { theme: "Aqua", value: "aqua" },
        // { theme: "Lofi", value: "lofi" },
        // { theme: "Pastel", value: "pastel" },
        // { theme: "Fantasy", value: "fantasy" },
        // { theme: "Wireframe", value: "wireframe" },
        // { theme: "Black", value: "black" },
        // { theme: "Luxury", value: "luxury" },
        // { theme: "Dracula", value: "dracula" }
      ]
    }
  },
  methods: {
    switchTheme(newTheme) {
      console.log(newTheme)
      document.documentElement.setAttribute("data-theme", newTheme);
    }
  },
}
</script>

<style scoped>

</style>