<template>
  <div class="flex justify-center min-h-screen bg-base-200">
    <!-- THEME SELECTOR -->
    <div class="absolute top-0 right-0">
      <select-theme/>
    </div>

    <!-- PAGE CONTENT -->
    <div v-if="!begin && !finished" class="flex flex-col pt-6 md:w-1/2">
      <p class="text-center p-6 text-2xl" >Item Safari</p>
      <div class="flex flex-row w-full">
        <div class="grid flex-grow card rounded-box place-items-center">
          <video id="animation"  class="md:h-96"
                 autoplay
                 loop
                 muted
                 playsinline
          >
            <source src="../../assets/Animations/safari.mp4" type="video/mp4">
          </video>
        </div>
      </div>
      <div>
        <p class="text-center p-6 text-lg">Let's go on an adventure and see what we can find. As you find each item on the screen, tap it to move onto the next item.</p>
      </div>
      <div class="flex justify-evenly p-2">
        <label @click="routeBegin()" class="btn btn-primary">Let's Begin</label>
      </div>
    </div>

    <div v-if="begin" class="pt-6 md:w-1/2">
      <p class="text-center p-6 text-4xl" >Item Safari</p>
      <div class="flex flex-row w-full">
        <div class="grid flex-grow card place-items-center">
          <img :src="getItem()" class="h-96 p-4" alt=""/>
        </div>
      </div>
      <div class="flex flex-col justify-center">
        <div class="pt-6">
          <p class="text-center text-lg">
            Find a <strong>{{ currentItem }}</strong> before the timer runs out.
          </p>
          <p class="text-7xl text-center p-2">{{countDown}}</p>
        </div>
        <div class="flex justify-evenly p-2">
          <p @click="clickGotIt" class="btn btn-primary w-1/2">
            Got it!
          </p>
        </div>

      </div>
    </div>


    <div v-if="finished" class="pt-6 md:w-1/2">
      <p class="text-center p-6 text-4xl" >Item Safari</p>
      <div class="flex flex-col w-full">

        <div>
          <p class="text-center p-6 text-lg">Great work!</p>
        </div>
        <div>
          <p class="text-center text-xl">You scored <strong>{{ score }} / {{ counter }}</strong></p>
        </div>
        <div class="flex justify-evenly p-6">
          <label @click="routeForward()" class="btn btn-primary">Finish</label>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import selectTheme from "../../components/selectTheme";
import {routeHelper} from "../../main";

export default {
  name: "Safari",
  mounted() {
    routeHelper.checkRoute(this.$route.params.previous, "strategypicker", this.$router)
    this.score = 0;
    this.items = [
      {name: 'Clock', image: 'clock'},
      {name: 'Toothbrush', image: 'toothbrush'},
      {name: 'Rubbish Bin', image: 'bin'},
      {name: 'Shoe', image: 'shoe'},
      {name: 'Hat', image: 'hat'},
      {name: 'Bag', image: 'bag'},
      {name: 'Towel', image: 'towel'},
      {name: 'Coffee Cup', image: 'coffee'},
      {name: 'Pet', image: 'pet'},
      {name: 'Chair', image: 'chair'},
      {name: 'Table', image: 'table'},
      {name: 'Soap', image: 'soap'},
      {name: 'Toilet', image: 'toilet'},
      {name: 'Oven', image: 'oven'},
      {name: 'Fridge', image: 'fridge'},
    ]
  },
  data() {
    return {
      items: [],
      begin:false,
      finished: false,
      currentItem: "",
      currentItemURL: "",
      countDown: 15,
      counter: 0,
      score: 0
    }
  },
  components: {
    selectTheme,
  },
  methods: {
    routeForward() {
      this.$router.push({name: "checkemotion", params: {"previous": "strategy"}})
    },
    routeBegin() {
      this.begin = true;
      this.randomItem();
      this.countDownTimer()
    },
    clickGotIt() {
      this.score++;
      this.countDown = 15;
      this.randomItem();
    },
    randomItem() {
      if (this.counter >= 5) {
        this.finished = true;
        this.begin = null;
      } else {
        let item = this.items[Math.floor(Math.random()*this.items.length)];
        this.currentItem = item.name;
        this.currentItemURL = item.image;
        console.log(item)
        let index = this.items.indexOf(item);
        this.items.splice(index, 1);
        this.counter++;
      }
    },
    getItem() {
      return require(`../../assets/safari/${this.currentItemURL}.png`)
    },
    countDownTimer() {
      if(this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      } else {
        this.randomItem()
        this.countDown = 15
        this.countDownTimer()
      }
    },
  },
}
</script>

<style scoped>

</style>
