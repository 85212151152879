import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'

Vue.config.productionTip = false;

Vue.use(VueRouter);
import "@/assets/styles/tailwind.css"


//Components
import Login from "@/components/Login"
import EmotionPicker from "@/pages/EmotionPicker"
import StrategyPicker from "@/pages/StrategyPicker"
import ChangePassword from "./pages/ChangePassword";
import Tapping from "./pages/BehaviouralStrat/Tapping";
import HeelToe from "./pages/BehaviouralStrat/HeelToe";
import MuscleTighten from "./pages/BehaviouralStrat/MuscleTighten";
import BreakWall from "./pages/CBTStrat/BreakWall";
import GratefulList from "./pages/CBTStrat/GratefulList";
import IdentifyEmotions from "./pages/CBTStrat/IdentifyEmotions";
import BodyScan from "./pages/DBTStrat/BodyScan";
import Breathing from "./pages/DBTStrat/Breathing";
import GlitterJar from "./pages/DBTStrat/GlitterJar";
import Safari from "./pages/DBTStrat/Safari";
import Wave from "./pages/DBTStrat/Wave";
import Welcome from "./pages/Welcome";
import checkEmotion from "./pages/checkEmotion";
import Finish from "./pages/Finish";
import Admin from "./pages/Admin"
import {FirebaseHelper} from "./API/FirebaseHelper";
import {RouteHelper} from "./API/RouteHelper";
//Routes
const routes = [
  { path: '/', component: Login },
  { path: '/changepassword', component: ChangePassword, name: 'changepassword'},
  { path: '/emotionpicker', component: EmotionPicker, name: "emotionpicker"},
  { path: '/strategypicker', component: StrategyPicker, name: "strategypicker" },
  { path: '/welcome', component: Welcome, name: 'welcome'},
  { path: '/tapping', component: Tapping, name: 'tapping'},
  { path: '/heeltoe', component: HeelToe, name: 'heeltoe'},
  { path: '/muscletighten', component: MuscleTighten, name: 'muscletighten'},
  { path: '/breakwall', component: BreakWall, name: 'breakwall'},
  { path: '/gratefullist', component: GratefulList, name: 'gratefullist'},
  { path: '/identifyemotions', component: IdentifyEmotions, name: 'identifyemotions'},
  { path: '/bodyscan', component: BodyScan, name: 'bodyscan'},
  { path: '/breathing', component: Breathing, name: 'breathing'},
  { path: '/glitterjar', component: GlitterJar, name: 'glitterjar'},
  { path: '/safari', component: Safari, name: 'safari'},
  { path: '/wave', component: Wave, name: 'wave'},
  { path: '/emotioncheck', component: checkEmotion, name: 'checkemotion'},
  { path: '/finish', component: Finish, name: 'finish'},
  { path: '/admin', component: Admin, name: 'admin'}
];

//Create Router
const router = new VueRouter({
  routes // short for `routes: routes`
});

//Create Firebase
const firebaseHelper = new FirebaseHelper();

const routeHelper = new RouteHelper();


export {firebaseHelper, routeHelper};

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
