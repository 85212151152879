<template>
    <div class="flex justify-center my-auto">
        <div class="pt-20">
            <div class="px-16">
                Welcome to C.O.P.E!
                Since it is your first time, please change your password.
                <div>
                    Make sure it is something you can remember!
                </div>

            </div>

            <div class="flex justify-center pt-20">
                <div>
                    <div>
                        New Password
                    </div>
                    <input type="password" class="input input-bordered" v-model="newPassword">
                </div>

            </div>
            <div class="justify-center flex">
                <div>
                    <div>
                        Confirm Password
                    </div>
                    <input type="password" class="input input-bordered" v-model="confirmPassword">
                </div>
            </div>
            <div class="">
                <div v-if="isError" class="text-red-600 pt-2 text-center">
                    {{errorMessage}}
                </div>
            </div>

            <div class="flex justify-center">

                <div class="pt-2">
                    <button class="btn btn-primary" v-on:click="submit">Submit</button>
                </div>
            </div>

        </div>
    </div>

</template>

<script>
    import firebase from "firebase/app";
    import "firebase/auth";
    import {firebaseHelper, routeHelper} from "../main";
    export default {
        name: "ChangePassword",
        mounted() {
            routeHelper.checkRoute(this.$route.params.previous, "base", this.$router)
        },
        watch: {
            newPassword() {
                this.checkAll();
                return true;
            }
        },
        data() {
            return {
                newPassword: "",
                confirmPassword: "",
                isError: false,
                errorMessage: ""
            }
        },
        methods: {
            async submit() {
                this.checkAll();
                if (!this.isError) {
                    this.checkSame();
                }

                if (!this.isError) {
                    var user = firebase.auth().currentUser;
                    var status = await user.updatePassword(this.newPassword);
                    if (status === undefined) {
                        await firebaseHelper.setFirstTime(localStorage.getItem("uid"));
                        this.routeForward();
                    } else {
                        this.isError = true;
                        this.errorMessage = "An error has occurred. Please try again with a different password, or try logging in again."
                    }

                }
            },

            checkAll() {
                this.isError = false;
                this.checkLength();
                if (!this.isError) {
                    this.checkRegex();
                }
            },
            checkLength() {
                if (this.newPassword.length < 6 || this.newPassword.length > 32) {
                    this.isError = true;
                    this.errorMessage = "The password must contain at least 6 characters, and no more than 32."
                }
            },
            checkRegex() {
                const regex = new RegExp(/^[a-zA-Z0-9]+$/i);
                if (!regex.test(this.newPassword)) {
                    this.isError = true;
                    this.errorMessage = "The password must contain only letters or numbers."
                }
            },
            checkSame() {
                if (this.newPassword !== this.confirmPassword) {
                    this.isError = true;
                    this.errorMessage = "Both passwords do not match."
                }
            },
            routeForward() {
                this.$router.push({name: 'welcome', params: {"previous": "base"}} )
            }
        },
    }
</script>

<style scoped>

</style>
