<template>
  <div class="flex justify-center min-h-screen bg-base-200">
    <!-- THEME SELECTOR -->
    <div class="absolute top-0 right-0">
      <select-theme/>
    </div>

    <!-- PAGE CONTENT -->
    <div class="flex flex-col pt-6 md:w-1/2">
      <p class="text-center pt-6 text-4xl" >Glitter Jar</p>
      <div class="flex flex-row w-full">
        <div class="grid flex-grow card rounded-box place-items-center">
          <video id="animation" class="md:h-96"
                 muted
                 playsinline
          >
            <source src="../../assets/Animations/glitter_background.mp4" type="video/mp4">
            <source src="../../assets/Animations/glitter.webm" type="video/webm">
          </video>
        </div>
      </div>
      <div>
        <p v-if="!begin && !finished" class="text-center p-6">Once you click let's begin, watch how the glitter is all over the place, it's messy and not clear.
          Sometimes our emotions feel this way and we can't think properly. Watch as the glitter settles and you can see clearly again.
          As the glitter settles focus on taking big deep breaths in through your nose and out through your mouth.</p>
        <p v-if="begin" class="text-7xl text-center p-2"> {{ this.countDown }}</p>
      </div>
      <div class="flex justify-evenly p-2">
        <label v-if="!begin && !finished" @click="beginVideo" class="btn btn-primary">Let's Begin</label>
        <label v-if="finished" @click="routeForward" class="btn btn-primary">Finish</label>
      </div>
    </div>
  </div>
</template>

<script>
import selectTheme from "../../components/selectTheme";
import {routeHelper} from "../../main";

export default {
  name: "GlitterJar",
  data() {
    return {
      begin: false,
      finished: false,
      countDown: 20,
    }
  },
  components: {
    selectTheme,
  },
  mounted() {
    routeHelper.checkRoute(this.$route.params.previous, "strategypicker", this.$router)
  },
  methods: {
    routeForward() {
      this.$router.push({name: "checkemotion", params: {"previous": "strategy"}})
    },
    beginVideo() {
      let animationPlayer = document.getElementById('animation');
      animationPlayer.play();
      this.countDownTimer();
      this.begin = true;
    },
    countDownTimer() {
      if(this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      } else {
        this.begin = false
        this.finished = true;
      }
    },
  },
}
</script>

<style scoped>

</style>
