<template>
  <div class="flex justify-center min-h-screen bg-base-200">
    <!-- THEME SELECTOR -->
    <div class="absolute top-0 right-0">
      <select-theme/>
    </div>

    <!-- PAGE CONTENT START -->
    <div v-if="!begin && !finish" class="flex flex-col pt-6 md:w-1/2">
      <p class="text-center p-6 text-4xl" >Identify Emotions</p>
      <div class="flex flex-row w-full">
        <div class="grid flex-grow card place-items-center rounded-box">
          <video class="rounded-2xl" id="animation"
                 autoplay
                 loop
                 muted
                 playsinline
          >
            <source src="../../assets/Animations/emotions/expressions.mp4" type="video/mp4">
          </video>
        </div>
      </div>
      <div>
        <p class="text-center p-6 text-lg">Learning about our emotions starts with being able to name them.
          Please look at the pictures on screen and pick the mood that you think best matches the face.</p>
      </div>
      <div class="flex justify-evenly p-2">
        <label @click="routeBegin" class="btn btn-primary">Let's Begin</label>
      </div>
    </div>

    <!-- PAGE CONTENT BEGIN -->
    <div v-if="begin" class="pt-6 md:w-1/2">
      <p class="text-center p-6 text-4xl" >Identify Emotions</p>
      <div class="flex flex-row w-full">
        <div class="grid flex-grow card place-items-center">
          <img :src="getEmotion()" class="h-96 p-4"/>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 p-2">
        <div v-for="item in emotions" :key="item.message" class="">
          <div class="card bg-primary shadow-xl">
            <div class="h-full">
              <p @click="checkOption(item)" class="btn btn-primary w-full">{{ item.message }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="incorrectOption" class="p-4">
        <div class="alert alert-error">
          <div class="flex-1">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="w-6 h-6 mx-2 stroke-current">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"></path>
            </svg>
            <label>Oops! That isn't quite right.</label>
          </div>
        </div>
      </div>
    </div>

    <div v-if="finish" class="pt-6 md:w-1/2">
      <p class="text-center p-6 text-4xl" >Identify Emotions</p>
      <div class="flex flex-row w-full">
        <div class="grid flex-grow card place-items-center">
          <p class="text-4xl pt-16">Great Work!</p>
        </div>
      </div>

      <div class="flex justify-evenly p-16">
        <label @click="routeForward()" class="btn btn-primary">Finish</label>
      </div>

    </div>



  </div>
</template>

<script>
import selectTheme from "../../components/selectTheme";
import {routeHelper} from "../../main";

export default {
  name: "IdentifyEmotions",
  data() {
    return {
      begin: false,
      finish: false,
      currentEmotion: "",
      currentEmotionURL: "",
      emotions: [],
      incorrectOption: false,
      randomImg: 0
    }
  },
  mounted() {
    routeHelper.checkRoute(this.$route.params.previous, "strategypicker", this.$router)
    this.randomImg = Math.floor(Math.random() * 2);

    this.emotions = [
      { imgURL: 'exp_angry', message: 'Angry' },
      { imgURL: 'exp_annoyed', message: 'Annoyed'},
      // { imgURL: 'exp_disappointed', message: 'Disappointed' },
      { imgURL: 'exp_excited', message: 'Excited' },
      { imgURL: 'exp_happy', message: 'Happy' },
      { imgURL: 'exp_sad', message: 'Sad' },
      { imgURL: 'exp_scared', message: 'Scared' }
    ]
  },
  components: {
    selectTheme,
  },
  methods: {
    routeBegin() {
      this.begin = true;
    },
    routeForward() {
      this.$router.push({name: "checkemotion", params: {"previous": "strategy"}})
    },
    randomEmotion() {
      if (this.emotions.length <= 0) {
        this.finish = true;
        this.begin = false;
      } else {
        let emotion = this.emotions[Math.floor(Math.random()*this.emotions.length)];
        this.currentEmotion = emotion.message;
        this.currentEmotionURL = emotion.imgURL;
      }

    },
    getEmotion() {
      this.randomEmotion();
      return require(`../../assets/Animations/emotions/${this.currentEmotionURL}${this.randomImg}.png`)
    },
    checkOption(selected) {
      if (selected.message === this.currentEmotion) {
        let index = this.emotions.indexOf(selected);
        this.emotions.splice(index, 1);
        this.randomEmotion();
        this.incorrectOption = false;
      } else {
        this.incorrectOption = true;
      }
    }
  },
}
</script>

<style scoped>
</style>
