<template>
  <div>
    <div class="flex justify-center pt-4" v-if="!loggedIn">
      <div class="">
        <div class="font-bold">
          Username:
          <input type="text" v-model="username" class="border-gray-300 border-2 rounded-xl p-1">
        </div>
        <div class="font-bold">
          Password:
          <input type="password" v-model="password" class="border-gray-300 border-2 rounded-xl ml-1 p-1">
        </div>
        <div v-if="isError" class="font-bold text-red-600">
          Error: Ensure your password is correct.
        </div>
        <div class="flex justify-end pt-2">
          <div>
            <div v-if="isLoggingIn">
              <button
                  class="bg-gray-500 rounded-full font-bold text-white px-4 py-2 transition duration-300 ease-in-out hover:bg-gray-600">
                Logging in...
              </button>
            </div>
            <div v-else>
              <button v-on:click="logIn"
                      class="bg-blue-500 rounded-full font-bold text-white px-4 py-2 transition duration-300 ease-in-out hover:bg-blue-600">
                Log In
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>


    <div v-else class="">
      <div class="flex justify-center h-screen">

        <div class="my-auto">
          <div v-if="isExporting" class="">
            <button
                class="bg-gray-500 rounded-full font-bold text-white px-4 py-2 transition duration-300 ease-in-out hover:bg-gray-600 mr-6">
              Exporting...
            </button>
          </div>
          <div v-else>
            <button v-on:click="exportAll" title="Exports all history as a .csv file."
                    class="bg-blue-500 rounded-full font-bold text-white px-4 py-2 transition duration-300 ease-in-out hover:bg-blue-600 mr-6">
              Export All
            </button>
          </div>
        </div>
        <div class="my-auto">
          <div>
            <div>
              <select v-model="selectedUser" class="w-32">
                <option :key="'default'" :value="''">
                  Select a user...
                </option>
                <option v-for="option in userArray" :value="option.uid" :key="option.userId">
                  {{ option.userId }}
                </option>
              </select>
            </div>

            <div v-if="selectedUser === ''" title="Exports a certain users history as a .csv file." class="pt-2">
              <button
                  class="bg-gray-500 rounded-full font-bold text-white px-4 py-2 transition duration-300 ease-in-out hover:bg-gray-600 mr-6">
                Export User
              </button>
            </div>
            <div v-else>
              <div v-if="isExporting">
                <button
                    class="bg-gray-500 rounded-full font-bold text-white px-4 py-2 transition duration-300 ease-in-out hover:bg-gray-600 mr-6">
                  Exporting...
                </button>
              </div>
              <div v-else>
                <button v-on:click="exportUser"
                        class="bg-blue-500 rounded-full font-bold text-white px-4 py-2 transition duration-300 ease-in-out hover:bg-blue-600 mr-6">
                  Export User
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="my-auto">
          <div>
            <div>
              <div class="font-bold">
                <div class="flex" title="What you will use to uniquely identify a certain user.">
                  Unique Identifier (ID)
                  <span class="pl-2">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </span>
                </div>
                <input type="text" v-model="userId" placeholder="Re1gHwe"
                       class="border-gray-300 border-2 rounded-xl p-1">
                <div class="flex" title="What username a user uses to log into the COPE app.">
                  Usercode
                  <span class="pl-2">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </span>
                </div>
                <input type="text" v-model="userLogin" placeholder="john1"
                       class="border-gray-300 border-2 rounded-xl p-1">
              </div>
            </div>

            <div v-if="userId !== '' && userLogin !== ''" class="mt-2">
              <div v-if="isExporting">
                <button
                    class="bg-gray-500 rounded-full font-bold text-white px-4 py-2 transition duration-300 ease-in-out hover:bg-gray-600 mr-6">
                  Exporting...
                </button>
              </div>
              <div v-else>
                <button v-on:click="createUser"
                        class="bg-blue-500 rounded-full  font-bold text-white px-4 py-2 transition duration-300 ease-in-out hover:bg-blue-600 mr-6">
                  Create User
                </button>
              </div>
            </div>
            <div v-else>
              <button
                  class="bg-gray-500 rounded-full font-bold text-white px-4 py-2 transition duration-300 ease-in-out hover:bg-gray-600 mr-6">
                Create User
              </button>
            </div>
            <div v-if="successMessage !== ''" class="text-green-600">
              {{successMessage}}
            </div>
            <div v-if="errorMessage !== ''" class="text-red-600">
              {{ errorMessage }}
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {firebaseHelper} from "../main";
import {saveAs} from 'file-saver';
import "firebase/auth";
import firebase from "firebase/app";

export default {
  name: "Admin",
  data() {
    return {
      username: "",
      password: "",
      isError: false,
      loggedIn: false,
      isLoggingIn: false,

      jsonArray: [],
      isExporting: false,

      userArray: [],
      selectedUser: "",

      userId: "",
      userLogin: "",

      successMessage: "",
      errorMessage: "",
    }
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    async logIn() {
      this.isError = false;
      this.isLoggingIn = true;
      if (this.username === "adminadmin") {
        this.loginStatus = await firebaseHelper.firebaseLogin(this.username, this.password);
        if (this.loginStatus === undefined) {
          this.loggedIn = true;
        } else {
          this.isError = true;
        }
      } else {
        this.isError = true;
      }
      this.isLoggingIn = false;

    },

    async getUsers() {
      await firebaseHelper.db.collection("users").get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.userArray.push({
                userId: doc.data().userId,
                uid: doc.id
              })
            });
          })
    },

    async exportAll() {
      this.isExporting = true;
      await this.convertLogData();
      this.isExporting = false;
    },

    async exportUser() {
      this.isExporting = true;
      await this.convertUserLogData();
      this.isExporting = false;
    },

    async createUser() {
      this.successMessage = "";
      this.errorMessage = "";
      const regex = new RegExp(/^[a-z0-9]+$/i);
      if (!regex.test(this.userLogin)) {
        this.isError = true;
        this.errorMessage = "The usercode must contain only letters or numbers."
      } else {
        try {
          await firebaseHelper.db.collection("users").doc(this.userId).get().then(async (snapshot) => {
            if (snapshot.exists) {
              this.errorMessage = "User with unique identifier " + this.userId + " already exists."
            } else {
              firebase.auth().createUserWithEmailAndPassword(this.userLogin + "@cope.com", "Pizza12").then((userCredential) => {
                var user = userCredential.user;
                firebaseHelper.db.collection("users").doc(user.uid).set({
                  theme: "light",
                  userId: this.userId,
                  firstTime: true
                }).then(() => {
                  this.successMessage = "User " + this.userId + " was successfully created with Usercode " + this.userLogin + "."
                })

              });

            }
          })

        } catch (e) {
          this.errorMessage = e
        }
      }


    },

    /**
     * Gets all of the datapoints for a user log.
     */
    async convertUserLogData() {
      this.jsonArray = [];
      await firebaseHelper.db.collection("users").doc(this.selectedUser).collection("log").get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          var jsonObject = doc.data();
          jsonObject.id = doc.id;
          this.jsonArray.push(jsonObject);
        });
      });

      firebaseHelper.db.collection("users").doc(this.selectedUser).get().then((doc) => {
        var userId = doc.data().userId;
        var csvString = (this.JsonLogToCSV(["Date", "Time", "user", "initalRating", "initialEmotionSelected", "initialDescription", "postRating", "duration (seconds)", "activitySelected", "givenStrategy"]));
        var blob = new Blob([csvString], {type: "text/csv;charset=utf-8"});
        saveAs(blob, "cope-user-" + userId + "-log.csv");
      });

    },

    /**
     * Gets all of the datapoints from the log.
     */
    async convertLogData() {
      this.jsonArray = [];
      await firebaseHelper.db.collection("log").get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          var jsonObject = doc.data();
          jsonObject.id = doc.id;
          this.jsonArray.push(jsonObject);
        });
      });
      var csvString = (this.JsonLogToCSV(["Date", "Time", "user", "initalRating", "initialEmotionSelected", "initialDescription", "postRating", "duration (seconds)", "activitySelected", "givenStrategy"]));
      var blob = new Blob([csvString], {type: "text/csv;charset=utf-8"});
      saveAs(blob, "cope-full-log" + ".csv");
    },

    JsonLogToCSV(JsonFields) {
      var csvStr = JsonFields.join(",") + "\n";

      this.jsonArray.forEach(element => {
        var timeCompleted = element.end.toDate();
        var date = Math.round((timeCompleted - new Date(1899, 11, 30)) / 8.64e7);
        var time = timeCompleted.toString().split(' ')[4];
        var user = element.userId;
        var initialRating = element.initialRating;
        var initialEmotionSelected = element.initialEmotionSelected;
        var initialDescription = element.initialDescription.replace(/[\n\r]+/g, ' ');
        var postRating = element.postRating;
        var duration = element.durationSeconds;
        var activitySelected = element.activitySelected;
        var givenStrategy = element.givenStrategy;
        csvStr += date + "," + time + "," + user + ',' + initialRating + "," + initialEmotionSelected + "," + initialDescription + "," + postRating + "," + duration + "," + activitySelected + "," + givenStrategy + "\n";
      });
      return csvStr;
    },
  }
}
</script>

<style scoped>

</style>
