import { render, staticRenderFns } from "./IdentifyEmotions.vue?vue&type=template&id=178b1f34&scoped=true&"
import script from "./IdentifyEmotions.vue?vue&type=script&lang=js&"
export * from "./IdentifyEmotions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "178b1f34",
  null
  
)

export default component.exports